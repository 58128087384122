import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import("@/views/home.vue"),
    meta: { requiresRole: 'healthcare', requiresAuth: true },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import("@/views/password-entry.vue"),
  },
  {
    path: '/gateway',
    name: 'gateway',
    component: () => import("@/views/gateway.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: '/public-member',
    name: 'public-member',
    component: () =>
      import('@/views/public-member.vue').catch((error) => {
        console.error('Failed to load the component:', error);
        // Optionally, redirect to an error page
        return import('@/views/not-found.vue');
      }),
    meta: { requiresAuth: true },
  },
  {
    path: '/about',
    name: 'about',
    component: () => import("@/views/about.vue"),
    meta: { requiresRole: 'healthcare' },
  },
  {
    path: '/clinical-studies',
    name: 'clinical-studies',
    component: () => import("@/views/clinical-studies.vue"),
    meta: { requiresRole: 'healthcare' },
  },
  {
    path: '/media',
    name: 'media',
    component: () => import("@/views/media.vue"),
    meta: { requiresRole: 'healthcare' },
  },
  {
    path: '/side-effects',
    name: 'side-effects',
    component: () => import("@/views/side-effects.vue"),
    meta: { requiresRole: 'healthcare' },
  },
  {
    path: '/not-found',
    name: 'not-found',
    component: () => import("@/views/not-found.vue"),
  },
  {
    path: '/terms',
    name: 'terms',
    component: () => import("@/views/terms.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: '/public-terms',
    name: 'public-terms',
    component: () => import("@/views/public-terms.vue"),
    meta: { requiresAuth: true },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
  linkExactActiveClass: "exact-active",
  scrollBehavior(to) {
    // Check if there's a hash in the URL (e.g., #indications)
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth' // Enables smooth scrolling
      };
    } else {
      return { top: 0 }; // Default scroll position to the top
    }
  }
});

const allowed = ['privacy', 'public-terms', 'public-member']

// Navigation guard to check if audience is selected
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (localStorage.getItem('auth') != 'authenticated') {
      next('/login'); // Redirect to password entry
    }
  }

  // Retrieve userRole from localStorage or other storage.
  let userRole = localStorage.getItem('userRole');

  // Allow access to "privacy" and "terms" pages without any role check
  if (allowed.includes(to.name)) {
    next();
    return;
  }

  // Check if the route requires a role and if the user has that role
  if (to.meta.requiresRole) {
    if (userRole === to.meta.requiresRole) {
      next();
      return;
    } else {
      next({ name: 'gateway' });
      return;
    }
  } else {
    next();
    return;
  }
});

export default router;
