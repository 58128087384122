<template>
  <span @pointerover="onPointerover" @pointerleave="onPointerleave" ref="reference">
    <slot name="target" />
  </span>
  <Teleport to="body">
    <Transition enter-active-class="duration-300 ease-out" enter-from-class="transform opacity-0"
      enter-to-class="opacity-100" leave-active-class="duration-200 ease-in" leave-from-class="opacity-100"
      leave-to-class="transform opacity-0">
      <div v-if="isOpen" :style="{ position: strategy, top: floatingTop, left: floatingLeft }"
        class="tooltip z-10 text-wrap shadow-sm transition-opacity" ref="floating">
        <!-- Tooltip content on top -->
        <slot name="content" class="z-10 text-white" />
        <!-- Tooltip arrow -->
        <!-- <span :style="{
          top: floatingArrowTop,
          left: floatingArrowLeft,
          ...floatingArrowBalance,
        }" class="absolute z-0 rotate-45 bg-inherit aspect-square w-2" ref="floatingArrow" /> -->
      </div>
    </Transition>
  </Teleport>
</template>

<script>
import { Teleport, Transition, ref, computed } from "vue";
import { useFloating, autoUpdate, arrow, flip, offset } from "@floating-ui/vue";

export default {
  name: 'TooltipComponent',
  setup() {
    const OPPOSITE_SIDE_BY_SIDE = {
      top: "bottom",
      right: "left",
      bottom: "top",
      left: "right",
    };

    const isOpen = ref(false);
    const reference = ref(null);
    const floating = ref(null);
    const floatingArrow = ref(null);
    const { x, y, strategy, placement, middlewareData } = useFloating(
      reference,
      floating,
      {
        middleware: [arrow({ element: floatingArrow }), flip(), offset(5)],
        whileElementsMounted: autoUpdate,
      }
    );
    const side = computed(() => placement.value.split("-")[0]);
    const floatingTop = computed(() => `${(y.value ?? 0) + 22}px`);
    const floatingLeft = computed(() => `${x.value ?? 0}px`);
    const floatingArrowX = computed(() => middlewareData.value.arrow?.x ?? null);
    const floatingArrowY = computed(() => middlewareData.value.arrow?.y ?? null);
    const floatingArrowTop = computed(() =>
      floatingArrowY.value === null ? "" : `${floatingArrowY.value}px`
    );
    const floatingArrowLeft = computed(() =>
      floatingArrowX.value === null ? "" : `${floatingArrowX.value}px`
    );
    const floatingArrowBalance = computed(() => ({
      [OPPOSITE_SIDE_BY_SIDE[side.value]]: "-4px",
    }));

    function onPointerover() {
      isOpen.value = true;
    }

    function onPointerleave() {
      isOpen.value = false;
    }

    return {
      isOpen,
      onPointerover,
      onPointerleave,
      floatingArrow,
      floatingArrowTop,
      floatingArrowLeft,
      floatingArrowBalance,
      strategy,
      floatingTop,
      floatingLeft,
      reference,
      floating
    };
  },
  components: {
    Transition,
    Teleport
  }
};
</script>