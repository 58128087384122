<template>
    <section class="relative bg-cover bg-top bg-no-repeat text-white h-full"
        :style="{ backgroundImage: `url('images/${medium}/wave.png')` }">
        <footer
            class='footer__disclaimer container relative absolute bottom:0 mx-auto max-w-screen-lg px-2 pt-52 sm:pt-96 xl:pt-80 sm:px-6 lg:flex lg:flex-col lg:items-center'>
            <div class='wrapper___inner mx-auto max-w-screen-xl px-2 pb-4 sm:pt-6 lg:px-8 items-center text-center'>
                <div class='footerlinks'>
                    <a href='/'>Home</a>
                    <a target='_blank' href=' https://www.gilead.co.uk/privacy-statements'>Privacy Policy</a>
                    <a href='/terms'>Terms of Use</a>
                    <a target='_blank' href='https://www.gilead.co.uk/privacy-statements/cookie-statement'>Cookies
                        Policy</a>
                </div>

                <p class='footer__disclaimer__boxed'>
                    Adverse events should be reported.
                    <br>
                    <br>
                    For Great Britain and Northern Ireland, reporting forms and information can be found at
                    <a href="https://coronavirus-yellowcard.mhra.gov.uk/">
                        https://coronavirus-yellowcard.mhra.gov.uk/
                    </a>
                    or via the Yellow Card app (download from the Apple App Store or Google Play Store).
                    <br>
                    <br>
                    For Ireland, reporting forms and information can be found at
                    <a href="https://www.hpra.ie">https://www.hpra.ie</a>
                    and can be reported to HPRA on <a href="tel:+35316764971">+353 1 6764971</a>. Adverse events should
                    also be reported
                    to Gilead to <a href="mailto:safety_fc@gilead.com">Safety_FC@gilead.com</a> or <a
                        href="tel:+441223897500">+44 (0)1223897500</a>.
                </p>

                <p class="font-light">
                    This website includes promotional content on the following Gilead products: Veklury®
                    (remdesivir)
                    <br>
                    Prescribing Information:
                    <TooltipComponent>
                        <template #target>
                            <a class="il -mobileicon underline" href="https://www.emcpi.com/pi/38228" target="_blank"
                                rel="nonreferrer">GB</a>
                        </template>
                        <template #content>{{ TooltipMsg }}</template>
                    </TooltipComponent> |
                    <TooltipComponent>
                        <template #target>
                            <a v-on:click="OpenURL('https://www.emcpi.com/pi/ni/423')" href="#"
                                class="text-black underline"> NI&amp;IE
                            </a>
                        </template>
                        <template #content>{{ TooltipMsg }}</template>
                    </TooltipComponent>
                </p>

                <p class="font-light">UK-VKY-0408 | November 2024</p>
            </div>

            <p class='footer__lower'>
                Gilead Sciences Ltd +44 (0) 8000 113 700; Registered as a limited company in England and Wales
                [registered number 02543818]; Registered office: 280 High Holborn, London, WC1V 7EE, England |
                <a href="mailto:ukmed.info@gilead.com" class="underline">UKMed.Info@gilead.com</a>
            </p>

            <div class='footer__lower__banner'>
                <img src="@/assets/images/logo-gilead-white.svg" class='footer__lower__logo inline-block'>
                <span class="text-sm text-white">© 2024 Gilead Sciences, Inc. All rights reserved. Gilead
                    and the Gilead logo are trademarks of Gilead Sciences, Inc.</span>
            </div>
        </footer>
    </section>
</template>

<script>
import TooltipComponent from './tooltip.vue';

export default {
    name: 'FooterComponent',
    props: {
        TooltipMsg: String,
        OpenURL: Function
    },
    computed: {
        medium() {
            if (this.isMobile()) {
                return "mobile"
            } else {
                return "desktop"
            }
        }
    },
    methods: {
        isMobile() {
            if (screen.width <= 760) {
                return true;
            }
            else {
                return false;
            }
        },
    },
    components: {
        TooltipComponent,
    },
};
</script>
